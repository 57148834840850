import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/": [~5],
		"/api/auth": [15],
		"/(app)/locations/[country=country]": [~7,[2],[3]],
		"/(app)/locations/[slug]": [~8,[2],[3]],
		"/(app)/locations/[...rest]": [~6,[2],[3]],
		"/(app)/profile": [9,[2],[3]],
		"/(app)/proposals": [~10,[2],[3]],
		"/(app)/proposals/[proposalId]": [~11,[2],[3]],
		"/(auth)/reset-password": [13,[4]],
		"/(auth)/signin": [14,[4]],
		"/(app)/templates/create": [12,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';